
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-lessons-list-report',
  templateUrl: './lessons-list-report.component.html',
  styleUrls: ['./lessons-list-report.component.css']
})
export class LessonsListReportComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'id',
    'title',
    'field_status',
    'nothing_1',
    // 'field_instructor',
    'view_user',
    'views_conditional_field',
    'title_1',
    'field_email',
    'field_date_and_time_value',
    'views_conditional_field_1',
    'field_date_and_time_1',
    'field_expiration_date_1',
    'field_duration',
    'created',
    'field_type',
    'field_repetition_frequency',
    // 'field_type_1',
    // 'field_category',
    // 'edit_events',
    'views_conditional_field_2',
    'event_actions',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Fix for instructor not set.
    let instructor = params.find(obj => {
      return obj.parameter === 'instructor'
    })

    if (typeof instructor == 'undefined') {
      this.queryParams.push({parameter: 'instructor', value: ''})
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getLessonListReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

        // Post process the data.
        this.data['results'].forEach(element => {
          if (element?.field_date_and_time && element?.field_duration) {
            // Calculate the start time + duration to get the end time of the event.
            element.field_expiration_date_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('hh:mm A');
            element.views_conditional_field_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('M/D/Y hh:mm A')
            // Pretty the date and time field.
            element.field_date_and_time = moment(element.field_date_and_time).format('M/D/Y hh:mm A')
          }

        });
      },
        error => this.handleError(error)
      )
  }

}
