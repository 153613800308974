export const environment = {
	production: true,
	name: "Studio 1",
	location: "Florida",
	drupalUsername: "",
	drupalPassword: "",
	hostUrl: 'https://studio1.letsdance.tech',
	drupalUrl: 'https://studio1.letsdance.tech',
	subFolder: '/app/',
};
